import trap from 'ci-trap-web';
import EventEmitter from 'events';

import { getUser } from '../Auth/utils';

import {
  GRABOXY_API_KEY,
  GRABOXY_TRAP_BUFFER_SIZE_LIMIT,
  GRABOXY_TRAP_BUFFER_TIMEOUT,
  GRABOXY_TRAP_URL,
  SUBMIT_DATA_TO_TRAP,
} from '../../config';

const MOUSE_MOVE_EVENT_TYPE = 0;

class TrapWrapper extends EventEmitter {
  constructor() {
    super();

    // Initialize Trap + Sentinel
    this.initialize(document, {
      /* eslint-disable no-underscore-dangle */
      apiKeyName: 'GRABOXY-API-KEY',
      apiKeyValue: getUser()?.profile?.api_key_value ?? GRABOXY_API_KEY,
      trapUrl: GRABOXY_TRAP_URL,
      trapBufferSizeLimit: GRABOXY_TRAP_BUFFER_SIZE_LIMIT,
      trapBufferTimeout: GRABOXY_TRAP_BUFFER_TIMEOUT,
      submitDataToTrap: SUBMIT_DATA_TO_TRAP,
      /* eslint-enable no-underscore-dangle */
    });
  }

  initialize(document, config) {
    this._config = {
      ...config,
      ...this._config,
    };
    trap.stop();
    trap.mount(document);
    trap.apiKeyName(this.config.apiKeyName);
    trap.apiKeyValue(this.config.apiKeyValue);
    trap.setTransportMethod(this.config.submitDataToTrap ? 'http' : 'none');
    trap.setCollectEvents(true);
    trap.url(this.config.trapUrl);
    trap.bufferSizeLimit(this.config.trapBufferSizeLimit);

    // TODO: https://redmine.cursorinsight.com/issues/8579
    trap.bufferTimeout(this.config.trapBufferTimeout
      ? parseInt(this.config.trapBufferTimeout, 10)
      : undefined);
  }

  get config() {
    return this._config;
  }

  emitEventCount() {
    this.emit('eventsCollected', {
      eventCount: trap.collectedEventCount(
        (item) => item[0] === MOUSE_MOVE_EVENT_TYPE,
      ),
    });
  }

  start() {
    trap.start();
    const t = this;
    this._interval = setInterval(
      () => { t.emitEventCount(); },
      this.config.sentinelInterval,
    );
    this.emitEventCount();
  }

  stop() {
    trap.stop();
    if (this._interval !== null) {
      clearInterval(this._interval);
    }
    this._interval = null;
  }

  get isRunning() {
    return (!!this._interval);
  }

  // eslint-disable-next-line class-methods-use-this
  get infoJSON() {
    return {
      date: new Date(),
      sessionId: trap.sessionId(),
      streamId: trap.streamId(),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  sendCustomData(customData) {
    trap.send(customData);
  }

  reset() {
    this.stop();
    this.flushCollectedEvents();
    trap.generateNewStreamId();
  }

  // eslint-disable-next-line class-methods-use-this
  flushCollectedEvents() {
    return trap.flushCollectedEvents();
  }
}

// Export a singleton only
const trapWrapper = new TrapWrapper();

export default trapWrapper;
