import trapWrapper from '../DataCollection/trapWrapper';

const copyToClipboard = () => {
  navigator.clipboard.writeText(JSON.stringify(trapWrapper.infoJSON));
};

const CopyInfo = () => (
  <div id="copy-info">
    <button onClick={copyToClipboard}>
      ✂
    </button>
  </div>
);

export default CopyInfo;
