import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { useEffect } from 'react';
import Logo from '../../components/Logo';

function LoginPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/');
    }
  }, [auth, navigate]);

  const isLoginDisabled = () => auth.activeNavigator
    || auth.isLoading
    || auth.error;

  const getDescription = () => {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return 'Sign in is in progress';
      case 'signoutRedirect':
        return 'Sign out is in progress';
      default:
        break;
    }

    if (auth.isLoading) {
      return 'Authentication script is loading';
    }

    if (auth.error) {
      return `Authentication error: ${auth.error.message}`;
    }

    return 'Login';
  };

  return (
    <div
      className="grow flex flex-col justify-center items-center h-full relative"
    >
      <Logo />
      <div className="mb-8 mt-16 font-bold">{getDescription()}</div>
      <div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={() => auth
          .signinRedirect({ redirect_uri: window.location.href })}
        disabled={isLoginDisabled()}
      >
        Login
      </button>
      </div>
    </div>
  );
}

export default LoginPage;
