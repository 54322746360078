const {
  ALLOW_DATA_DOWNLOAD: ALLOW_DATA_DOWNLOAD_STR,
  GRABOXY_API_KEY,
  GRABOXY_SENTINEL_URL,
  GRABOXY_TRAP_BUFFER_SIZE_LIMIT,
  GRABOXY_TRAP_BUFFER_TIMEOUT,
  GRABOXY_TRAP_URL,
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET,
  PERFORM_RAT_DETECTION: PERFORM_RAT_DETECTION_STR,
  REQUIRED_EVENT_COUNT: REQUIRED_EVENT_COUNT_STR,
  SUBMIT_DATA_TO_TRAP: SUBMIT_DATA_TO_TRAP_STR,
  USE_KEYCLOAK_AUTH: USE_KEYCLOAK_AUTH_STR,
// eslint-disable-next-line no-underscore-dangle
} = window.__RUNTIME_CONFIG__;

const convertToBoolean = (valueStr) => valueStr.toLowerCase() === 'true';

const ALLOW_DATA_DOWNLOAD = convertToBoolean(ALLOW_DATA_DOWNLOAD_STR);
const PERFORM_RAT_DETECTION = convertToBoolean(PERFORM_RAT_DETECTION_STR);
const SUBMIT_DATA_TO_TRAP = convertToBoolean(SUBMIT_DATA_TO_TRAP_STR);
const REQUIRED_EVENT_COUNT = parseInt(REQUIRED_EVENT_COUNT_STR, 10);
const USE_KEYCLOAK_AUTH = convertToBoolean(USE_KEYCLOAK_AUTH_STR);

export {
  ALLOW_DATA_DOWNLOAD,
  GRABOXY_API_KEY,
  GRABOXY_SENTINEL_URL,
  GRABOXY_TRAP_BUFFER_SIZE_LIMIT,
  GRABOXY_TRAP_BUFFER_TIMEOUT,
  GRABOXY_TRAP_URL,
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET,
  PERFORM_RAT_DETECTION,
  REQUIRED_EVENT_COUNT,
  SUBMIT_DATA_TO_TRAP,
  USE_KEYCLOAK_AUTH,
};
