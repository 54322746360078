import PropTypes from 'prop-types';

const Browser = ({ children }) => (
  <div className="w-3/4">
    <div className="mockup-browser border bg-base-300 h-full">
      <div className="mockup-browser-toolbar">
        <div className="input">https://bankingdemo.com</div>
      </div>
      <div
        className="flex justify-center items-center bg-base-200 h-full
        relative"
      >
        {children}
      </div>
    </div>
  </div>
);

Browser.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Browser;
