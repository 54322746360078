import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isCurrentUserAuthenticated } from './utils';

function RedirectIfNotAuthenticated({ children }) {
  const location = useLocation();

  if (!isCurrentUserAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

RedirectIfNotAuthenticated.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RedirectIfNotAuthenticated;
