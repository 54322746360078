import { User } from 'oidc-client-ts';
import {
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  USE_KEYCLOAK_AUTH,
} from '../../config';

const getUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

const isCurrentUserAuthenticated = () => {
  if (USE_KEYCLOAK_AUTH) {
    const user = getUser();
    return user !== null && !user.expired;
  }
  return true;
};

export {
  isCurrentUserAuthenticated,
  getUser,
};
