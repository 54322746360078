import { GRABOXY_API_KEY, GRABOXY_SENTINEL_URL } from '../../config';

import { getUser } from '../Auth/utils';

class SentinelClient {
  constructor() {
    /* eslint-disable no-underscore-dangle */
    this._config = {
      apiKeyName: 'GRABOXY-API-KEY',
      apiKeyValue: getUser()?.profile?.api_key_value ?? GRABOXY_API_KEY,
      sentinelUrl: GRABOXY_SENTINEL_URL,
    };
  }

  get config() {
    return this._config;
  }

  async detectRat(data) {
    const body = JSON.stringify({ data });
    // Set up content-type and its optional arguments: API key and envelope
    // encoding
    let contentType = 'text/plain';
    if (typeof this.config.apiKeyName === 'string'
      && this.config.apiKeyName !== ''
      && typeof this.config.apiKeyValue === 'string'
      && this.config.apiKeyValue !== '') {
      contentType += '; '
        + `${this.config.apiKeyName}=`
        + `${this.config.apiKeyValue}`;
    }
    contentType += '; encoding=json';
    const response = await fetch(this.config.sentinelUrl, {
      // *GET, POST, PUT, DELETE, etc.
      method: 'POST',
      // no-cors, *cors, same-origin
      mode: 'cors',
      // *default, no-cache, reload, force-cache, only-if-cached
      cache: 'no-cache',
      // include, *same-origin, omit
      credentials: 'omit',
      // necessary HTTP headers
      headers: {
        // No other headers are allowed in a preflight-less CORS request
        // For details: https://developer.mozilla.org/en-US/docs/Glossary/CORS-safelisted_request_header
        'Content-Type': contentType,
      },
      // manual, *follow, error
      redirect: 'follow',
      // no-referrer, *no-referrer-when-downgrade, origin,
      // origin-when-cross-origin, same-origin, strict-origin,
      // strict-origin-when-cross-origin, unsafe-url
      referrerPolicy: 'origin',
      // data to be sent
      body,
    });
    if (!response.ok) {
      const message = `An error occured: ${
        await this.getErrorMessage(response)}`;
      throw new Error(message);
    }
    // If OK, then parse and return the JSON result
    return response.json();
  }

  // eslint-disable-next-line class-methods-use-this
  async getErrorMessage(response) {
    try {
      const errorResponse = await response.json();
      if (errorResponse.result) {
        return errorResponse.result;
      }
    } catch {
      // Intentionally left empty, just return the status code as default error
      // message in this case
    }
    return response.status;
  }
}

// Export a singleton only
const sentinelClient = new SentinelClient();

export default sentinelClient;
