import LogoLight from '../../images/graboxy-light.svg';
import LogoDark from '../../images/graboxy-dark.svg';

const Logo = () => (
  <div>
    <div className="hidden dark:block">
      <img src={LogoDark} />
    </div>
    <div className="dark:hidden">
      <img src={LogoLight} />
    </div>
    <p>
      Remote Access Detection
      {' '}
      <span className="demo">Demo</span>
    </p>
  </div>
);

export default Logo;
