import React from 'react';
import ReactDOM from 'react-dom/client';

import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  OIDC_AUTHORITY,
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET,
} from './config';

const oidcUserStore = new WebStorageStateStore({ store: window.localStorage });
const oidcSigninCallback = () => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname,
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider
      authority={OIDC_AUTHORITY}
      client_id={OIDC_CLIENT_ID}
      client_secret={OIDC_CLIENT_SECRET}
      onSigninCallback={oidcSigninCallback}
      userStore={oidcUserStore}
    >
      <App />
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
