import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Scene from './components/Scene';
import CopyInfo from './components/CopyInfo';
import RedirectIfNotAuthenticated
  from './components/Auth/RedirectIfNotAuthenticated';

import Login from './pages/Login';
import Landing from './pages/Landing';

const App = () => (
  <Scene>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route
          path="/:program?/:step?"
          element={
            <RedirectIfNotAuthenticated>
              <Landing />
            </RedirectIfNotAuthenticated>
          }
        />
      </Routes>
    </BrowserRouter>
    <CopyInfo/>
  </Scene>
);

export default App;
